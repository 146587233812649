
import { Component, Mixins } from 'vue-property-decorator';

import HeaderInfosMixin from '../header-infos';
import ConfigurationDocMixin from '@/mixins/data-operations/doc/configuration-doc-mixin';

import { apiToStorageConf } from '@/store/modules/easy-firestore/api-to-storage-conf';
import { apiToStorageConfArchive } from '@/store/modules/easy-firestore/api-to-storage-conf-archive';

@Component
export default class StorageToStorageConfigurationsItemView extends Mixins(HeaderInfosMixin, ConfigurationDocMixin) {
	moduleName: string = apiToStorageConf.moduleName;
	archivedConfsModuleName: string = apiToStorageConfArchive.moduleName;

	get configurationData() {
		return [
			{
				component: 'view-header',
				props: {
					item: this.item,
					collection: this.moduleName,
					activeHeader: false,
					viewId: this.item.configuration_id,
					viewType: 'conf',
				},
			},
			{
				component: 'parameters-list',
				props: {
					groupTitle: 'Context',
					tooltip: true,
					description: 'Context of the Api to Storage configuration',
					paramItems: [
						{ id: 'configuration_type', label: 'Configuration Type', value: this.item.configuration_type },
						{ id: 'account', label: 'Account', value: this.item.account },
						{ id: 'environment', label: 'Environment', value: this.item.environment },
						{ id: 'client_type', label: 'Client Type', value: this.getFormattedClientType(this.item.client_type) },
						{
							id: 'client_version',
							label: 'Client Version',
							value: this.getFormattedClientVersion(this.item.client_version),
						},
					],
				},
			},
			{
				component: 'parameters-table',
				props: {
					tableTitle: 'Source API',
					description: 'Information about the Source API receiving the data payload',
					columns: [
						{
							label: 'Type',
							field: 'source.type',
							width: '100px',
						},
						{
							label: 'Project ID',
							field: 'source.gcp_project_id',
							width: '200px',
						},
						{
							label: 'Topic',
							field: 'pubsub_topic',
							width: '200px',
						},
					],
					rows: [this.item],
					vflexLength: 'xs10',
					lineNumbers: false,
					searchOptionsEnabled: false,
				},
			},
			{
				component: 'parameters-table',
				props: {
					tableTitle: 'GCS Destinations',
					description: 'Multi GCS Destinations for API Payload received',
					columns: [
						{ label: 'Type', field: 'type', width: '100px' },
						{ label: 'Destination Bucket', field: 'gcs_destination_bucket', width: '100px' },
						{ label: 'Destination Folder', field: 'gcs_destination_prefix', width: '100px' },
						{ label: 'File Name Template', field: 'gcs_filename_template', width: '100px' },
					],
					rows: [...this.item.destinations],
					vflexLength: 'xs10',
					lineNumbers: false,
					searchOptionsEnabled: false,
				},
			},
		];
	}
}
